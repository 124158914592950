<template>
    <div>
        <cosecha-urgente></cosecha-urgente>
        <screenshot name-pdf="Cruva de crecimiento"></screenshot>
        <b-row class="match-height">
            <b-col md="6">
                <good-table-basic
                    title=""
                    :data-table="dataTable1"
                    md="6"
                ></good-table-basic>
            </b-col>
            <b-col md="6">
                <good-table-basic
                    title=""
                    :data-table="dataTable2"
                ></good-table-basic>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import GoodTableBasic from "@/views/clients/blumar/components/GoodTableBasic.vue";
import CosechaUrgente from "@/views/clients/blumar/components/CosechaUrgente";
import Screenshot from "@/views/clients/blumar/components/Screenshot";
import { BRow, BCol } from "bootstrap-vue";

export default {
    components: {
        BRow,
        BCol,

        GoodTableBasic,
        CosechaUrgente,
        Screenshot
    },

    data() {
        return {
            dataTable1: {
                planning: [
                    {
                        campo1: "C",
                        campo2: "0,6409506481"
                    },
                    {
                        campo1: "W",
                        campo2: "-0,000695336"
                    },
                    {
                        campo1: "W2",
                        campo2: "1,47E-07"
                    },
                    {
                        campo1: "W3",
                        campo2: "-1,03E-11"
                    },
                    {
                        campo1: "T",
                        campo2: "0,1266363654"
                    },
                    {
                        campo1: "T2",
                        campo2: "-0,003673775"
                    },
                    {
                        campo1: "CC",
                        campo2: "0,979663066"
                    },
                    {
                        campo1: "D",
                        campo2: "0,0009028901"
                    },
                    {
                        campo1: "D2",
                        campo2: "1,47E-07"
                    },
                    {
                        campo1: "D3",
                        campo2: "1,17E-08"
                    },
                    {
                        campo1: "WC",
                        campo2: "0,0000453306"
                    },
                    {
                        campo1: "WC2",
                        campo2: "-1,08E-08"
                    },
                    {
                        campo1: "A",
                        campo2: "0,6988"
                    },
                    {
                        campo1: "B",
                        campo2: "1,0732"
                    }
                ],
                columns: [
                    {
                        label: "Constantes",
                        field: "campo1"
                    },
                    {
                        label: "Valor",
                        field: "campo2"
                    }
                ],
                verDetalle: false // para mostrar o no el ver detalle en el componente GoodTable
            },
            dataTable2: {
                planning: [
                    {
                        cepa: "M_LCY",
                        factor: "1,23",
                        tiempo: "11"
                    },
                    {
                        cepa: "H_LCY",
                        factor: "1,14",
                        tiempo: "13"
                    },
                    {
                        cepa: "M_AQG",
                        factor: "1,22",
                        tiempo: "11"
                    },
                    {
                        cepa: "H_AQG",
                        factor: "1,12",
                        tiempo: "13"
                    },
                    {
                        cepa: "M_HIB",
                        factor: "1,17",
                        tiempo: "12"
                    },
                    {
                        cepa: "H_HIB",
                        factor: "1,08",
                        tiempo: "13"
                    },
                    {
                        cepa: "SALAR",
                        factor: "1,00",
                        tiempo: "15"
                    }
                ],
                columns: [
                    {
                        label: "Cepa",
                        field: "cepa"
                    },
                    {
                        label: "Factor",
                        field: "factor"
                    },
                    {
                        label: "Tiempo 5.2 WFE",
                        field: "tiempo"
                    }
                ],
                verDetalle: false // para mostrar o no el ver detalle en el componente GoodTable
            }
        };
    }
};
</script>

<style lang="scss" scoped></style>
